<template>
    <div class="account-update-email" style="max-width:1000px;margin:auto">
        <form autocomplete="off" v-if="!token" v-on:submit.prevent="submit">
            <md-card>
                <md-card-header>
                    <h2>Update Account Email</h2>
                    <p>Current Email: {{user.email}}</p>
                </md-card-header>
                <md-card-content>
                    <md-input-container :class="{'md-input-invalid': errors.has('email')}">
                        <label for>New Email</label>
                        <md-input name="email" v-model="record.email" v-validate="'email'"></md-input>
                        <span class="md-error" v-show="errors.has('email')">{{ errors.first('email') }}</span>
                    </md-input-container>
                </md-card-content>
                <md-card-actions>
                    <submit :loading="loading" class="submitButton" icon="save" text="Send Email"></submit>
                </md-card-actions>
            </md-card>
        </form>

        <form autocomplete="off" v-if="token" v-on:submit.prevent="submit">
            <md-card>
                <md-card-header>
                    <h2>Update Account Email</h2>
                    <p v-if="email">Change Email: {{email}}</p>
                </md-card-header>

                <md-card-actions>
                    <submit :loading="loading" class="submitButton" icon="save" text="Confirm Update Email"></submit>
                </md-card-actions>
            </md-card>
        </form>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Submit from "../Submit";

export default {
    props: {
        token: {
            type: String
        },
        email: {
            type: String
        }
    },
    components: {
        Submit
    },

    data() {
        return {
            record: {}
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            loading: "loading"
        })
    },
    methods: {
        ...mapActions({
            logout: "user/logout"
        }),
        async submit() {
            if (this.token) {
                let result = await this.$store.dispatch(`crud/post`, {
                    api: `users/update-email/${this.token}`
                });
                this.$store.commit("setMessage", "Email Updated Successfully");
                this.logout();
            } else {
                let result = await this.$store.dispatch(`crud/post`, {
                    api: "users/send-update-email",
                    data: this.record
                });
                this.$store.commit("setMessage", "Validation Email Sent");
            }
        }
    }
};
</script>